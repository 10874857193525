@import '../../../../styles/imports/';

.pending-order-component {
  min-height: 100%;
  background-color: $light-grey;
  position: relative;

  .pending-order-list {
    padding: 3rem;
    @include prefixed(display, flex);
    @include prefixed(flex-wrap, wrap);
    @include prefixed(justify-content, 'flex-start');
    touch-action: pan-y;
  }

  .no-pending-orders {
    text-align: center;
    color: $white;
    font-size: 2.5rem;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .open {
    padding: 1rem 1.5rem;
    margin: 1rem;
  }
}
