@import "../../../../../../styles/imports/";

.add-product-form {
  width: 122rem;

  h2 {
    text-align: center;
    font-size: 2rem;
    font-family: $montserrat;
    margin-bottom: 2rem;
  }

  .block1 {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    @include prefixed(align-items, stretch);
    margin-bottom: 5rem;

    .left {
      width: 40%;
      margin-right: 2rem;

      .formRow {
        height: 100%;
      }
    }

    .right {
      width: 60%;

      .checkboxes {
        @include prefixed(display, flex);
        @include prefixed(justify-content, space-between);
        @include prefixed(flex-wrap, wrap);
        .left,
        .right {
          width: 50%;
          flex: 1;
        }

        .spicy-checkbox {
          @include prefixed(display, flex);
          @include prefixed(justify-content, space-between);
          @include prefixed(align-items, center);

          font-size: 1.6rem;
          flex-basis: 63%;
          margin-top: 2rem;

          .checkbox {
            @include prefixed(border-radius, $border-radius);
            border: solid 1px $medium-green;
          }

          .checkbox button {
            font-size: 1.6rem;
            padding: 1.25rem 3rem;
            background: none;
            color: #afafaf;
            border: none;

            &.active {
              background-color: $medium-green;
              color: white;
              font-weight: bold;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .restaurant-checkbox,
        .shop-checkbox,
        .halal-checkbox,
        .veggie-checkbox,
        .untransformed-checkbox,
        .menu-checkbox {
          @include prefixed(display, flex);
          @include prefixed(justify-content, space-between);
          @include prefixed(align-items, center);

          font-size: 1.6rem;

          .checkbox {
            @include prefixed(border-radius, $border-radius);
            border: solid 1px $medium-green;
          }

          .checkbox button {
            font-size: 1.6rem;
            padding: 1.25rem 3rem;
            color: #afafaf;
            background: none;
            border: none;

            &.active {
              background-color: $medium-green;
              color: white;
              font-weight: bold;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .restaurant-checkbox,
        .halal-checkbox,
        .veggie-checkbox,
        .shop-checkbox {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .block2 {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    @include prefixed(align-items, stretch);
    margin-bottom: 3rem;

    .left {
      width: 40%;
    }

    .right {
      width: 60%;
      padding-left: 2rem;
    }
  }
}

.spinner {
  margin: 5rem auto;
}
