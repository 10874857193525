@import "../../../../styles/imports/";

.order-unpaid-confirm-message {
  font-size: 2rem;
  text-align: center;

  .set-time {
    display: flex;

    .at {
      font-size: 2rem;
      padding-top: 3.5rem;
    }

    label {
      display: flex;
      justify-content: center;
    }
    input {
      width: 75%;
      margin: 0 0.5rem;
    }
  }

  .danger {
    color: $red-error;
    font-size: 2.2rem;
    font-weight: bold;
  }

  .buttons {
    margin: 2rem 0;
    @include prefixed(display, flex);

    button {
      color: white;
      font-size: 2rem;
      width: 50%;
      margin: 0 1rem;
      display: block;
      border: none;
      padding: 2rem 3rem;
      @include prefixed(border-radius, $border-radius);
      cursor: pointer;

      &.confirm-button {
        background-color: $medium-green;

        &:hover,
        &:focus {
          background-color: $dark-green;
        }
      }

      &.decline-button {
        background-color: $red-error;

        &:hover,
        &:focus {
          background-color: darken($red-error, 10%);
        }
      }
    }
  }
}
