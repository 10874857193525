@import "../../../../styles/imports/";

.kitchen-column {
  width: 25%;
  background-color: $dark-grey;

  .next {
    &.next-300 h4 {
      background-color: darken($yellow, 20%);
    }

    h4 {
      width: 100%;
      background-color: $red-error;
      color: $white;
      font-weight: bold;
      text-align: center;
      font-size: 4rem;
      text-transform: uppercase;
      padding: 2rem 0;
    }

    ul {
      list-style: none;
      margin: 2rem 0;
      padding-left: 2rem;

      li {
        span {
          font-weight: bold;
          color: $white;
          font-size: 4.5rem;
        }
        font-size: 4rem;
        color: $white;
        margin: 1rem 0;
      }
    }
  }
}
