@import '../../../../../../styles/imports/';

.customer-signin-form {
  h6 {
    font-size: 1.8rem;
    text-align: center;
  }

  form {
    input {
      margin: 2rem 0;
      width: 100%;
      display: block;
      padding: 1.5rem 3rem;
      border: solid 1px $medium-green;
      border-bottom: solid 5px $medium-green;
      font-size: 1.6rem;

      @include prefixed(border-radius, $border-radius);

      &:focus {
        outline: none;
        border: solid 2px $medium-green;
        border-bottom: solid 5px $medium-green;
      }
    }

    button {
      width: 100%;
      display: block;
      border: none;
      color: $white;
      padding: 1.5rem 3rem;
      @include prefixed(border-radius, $border-radius);
      background-color: $medium-green;
      cursor: pointer;
      font-size: 1.6rem;

      &:hover,
      &:focus {
        background-color: $dark-green;
      }
    }
  }
}
