@import '../../../../styles/imports/';

.delete-order-button {
  position: absolute;
  top: 2rem;
  right: 4rem;
  @include prefixed(border-radius, 50%);
  width: 5rem;
  height: 5rem;
  border: none;
  font-weight: bold;
  color: $white;
  background-color: $red-error;
  cursor: pointer;
  font-size: 2rem;

  &:hover,
  &:focus {
    background-color: darken($red-error, 10%);
  }
}

.send-ticket-confirm-message {
  font-size: 2rem;
  text-align: center;
  color: $grey;

  .warning-icon {
    font-weight: bold;
    color: $red-error;
    font-size: 2.2rem;
  }

  .buttons {
    margin: 2rem 0;
    @include prefixed(display, flex);

    button {
      color: white;
      font-size: 2rem;
      width: 50%;
      margin: 0 1rem;
      display: block;
      border: none;
      padding: 2rem 3rem;
      @include prefixed(border-radius, $border-radius);
      cursor: pointer;

      &.confirm-button {
        background-color: $medium-green;

        &:hover,
        &:focus {
          background-color: $dark-green;
        }
      }

      &.decline-button {
        background-color: $red-error;

        &:hover,
        &:focus {
          background-color: darken($red-error, 10%);
        }
      }
    }
  }
}
