@import "../../../../styles/imports/";

.order-item {
  @include prefixed(display, flex);
  @include prefixed(justify-content, "space-between");
  @include prefixed(align-items, "flex-start");
  user-select: none;

  font-size: 1.4rem;
  margin: 2rem 0;

  .order-item-title {
    @include prefixed(display, flex);

    .product-quantity {
      display: block;
      margin-right: 0.5rem;
    }

    .product-weight {
      margin-left: 0.5rem;
      font-size: 1.4rem;
    }
  }

  .order-item-cart-quantity {
    @include prefixed(display, flex);
    @include prefixed(align-items, center);

    .delete-item {
      color: $red-error;
      font-weight: bold;
      margin-left: 4rem;
      cursor: pointer;
      user-select: none;
    }

    .arrow {
      font-size: 3rem;
      margin: 0 2rem;
      cursor: pointer;
      user-select: none;

      &.arrow-left {
        margin-left: 0;
      }

      &:hover,
      &:focus {
        color: $medium-green;
      }
    }
  }

  .order-item-prices {
    .sale-price {
      color: $yellow;
    }
    .old-price {
      text-decoration: line-through;
      font-size: 1.4rem;
      margin-right: 0.5rem;
    }
  }
}
