@import "../../../../styles/imports/";

.checkout-buttons {
  @include prefixed(display, flex);
  @include prefixed(justify-content, center);
  margin-top: 5rem;

  button {
    border: none;
    background-color: $medium-green;
    color: $white;
    @include prefixed(border-radius, $border-radius);
    padding: 1.5rem 3rem;
    cursor: pointer;
    font-size: 2rem;
    margin: 0 2rem;

    &:hover,
    &:focus {
      background-color: $dark-green;
    }
  }
}
