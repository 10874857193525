@import "../../../../../../../../../../styles/imports/";

.mixing-container {
  h4 {
    text-align: center;
  }

  .mixing-list {
    @include prefixed(display, flex);
    @include prefixed(flex-wrap, wrap);

    .snack-element {
      @include prefixed(display, flex);
      @include prefixed(flex-direction, column);
      flex: 1 0 50%;
      margin-bottom: 1.5rem;

      padding-right: 1rem;
      max-width: 50%;

      .snack-item {
        @include prefixed(display, flex);
        @include prefixed(align-items, center);
        height: 4rem;

        .selected-number {
          margin-right: 0.5rem;
          font-weight: bold;
        }

        img {
          height: 3rem;
          width: 5rem;
        }
        p {
          font-size: 1.6rem;
          margin-left: 0.5rem;
        }
      }

      .arrows {
        @include prefixed(display, flex);
        @include prefixed(align-items, center);
        width: 100%;

        .arrow {
          width: 50%;
          text-align: center;
          padding: 0.5rem;

          cursor: pointer;

          &.arrow-down {
            background-color: $red-error;

            &:hover,
            &:active,
            &:focus {
              background-color: darken($red-error, 7%);
            }
          }

          &.arrow-up {
            background-color: $medium-green;

            &:hover,
            &:active,
            &:focus {
              background-color: darken($medium-green, 7%);
            }
          }

          .icon {
            font-size: 2.5rem;
            color: $white;
            font-weight: bold;
          }
        }
      }
    }
  }

  .validate-button {
    width: 100%;
    padding: 2rem 0;
    margin-top: 2rem;
    border: none;
    background: $medium-green;
    color: $white;
    font-size: 1.6rem;
    cursor: pointer;

    @include prefixed(border-radius, $border-radius);

    &:hover,
    &:focus,
    &:active {
      background: $dark-green;
    }
  }
}
