@import '../../../../../../styles/imports/';

.admin-reward-list {
  margin-top: 3rem;

  h6 {
    font-size: 1.8rem;
    margin: 2rem 0;
  }

  .product-item-list {
    list-style-type: none;
    .admin-products-category {
      @include prefixed(display, flex);
      @include prefixed(justify-content, flex-start);
      @include prefixed(flex-wrap, wrap);
    }
  }
}
