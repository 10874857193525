@import '../../../../styles/imports/';

.no-messages {
  padding: 5rem 3rem;
  font-size: 2rem;
}
.messages-page {
  padding: 5rem 3rem;
  flex: 1;

  h2 {
    font-size: 2.5rem;
  }

  .messages-container {
    .message-list {
      list-style-type: none;

      .message-list-item {
        background-color: $medium-green;
        @include prefixed(border-radius, $border-radius);
        margin: 2rem;
        padding: 2rem 4rem;
        position: relative;
        font-size: 1.6rem;

        .close-icon {
          position: absolute;
          top: 1rem;
          right: 1rem;
          color: $white;
          cursor: pointer;
          background-color: $red-error;
          @include prefixed(border-radius, 50%);
          padding: 0.5rem;

          svg {
            font-size: 2rem;
            position: relative;
            top: 2px;
          }

          &:hover {
            background-color: darken($red-error, 10%);
          }
        }

        .message-from {
          color: $white;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
