@import '../../styles/imports/';

.form-multiple-container {
  width: 100%;

  &.form-multiple-error {
    label,
    label:focus-within {
      color: $red-error;
    }

    .form-multiple-input-and-button input {
      border: 2px solid $red-error;

      &:focus {
        border-bottom: 5px solid $red-error;
      }
    }
  }

  label {
    font-family: Roboto;
    font-size: 1.6rem;
    transition: all 0.1s ease-in;
    position: relative;

    &:focus-within {
      color: $dark-green;
    }
  }

  .form-multiple-input-and-button {
    display: flex;

    input {
      display: block;
      width: 100%;
      padding: 0.5rem 2rem;
      margin: 0.75rem auto;
      font-size: 1.6rem;
      text-align: left;
      border-radius: 0.3rem;
      border: none;
      border-bottom: 5px solid $light-grey;
      outline: none;
      background-color: none;
      font-family: Roboto;
    }

    button {
      padding: 1rem 3rem;
      background-color: $medium-green;
      border-radius: 4px;
      border: none;
      color: $white;
      font-family: Roboto;
      font-size: 1.6rem;
      margin-left: 2rem;

      &:hover {
        background-color: $dark-green;
      }
    }
  }

  .ingredients {
    margin: 1rem auto;

    .ingredients-title {
      font-size: 1.8rem;
      font-family: Roboto;
    }
    ul {
      margin: 2rem 0;
      display: flex;
      list-style-type: none;

      li {
        cursor: pointer;
        padding: 1rem 2rem;
        background-color: $dark-grey;
        margin: 0.5rem;
        font-size: 1.6rem;
        color: $white;
        border-radius: 4px;
        text-transform: capitalize;

        &:hover {
          background-color: $red-error;
        }
      }
    }
  }
}
