@import '../../../../styles/imports/';

.separation-line {
  position: relative;
  margin: 2.5rem 0;

  p {
    font-size: 1.5rem;
    position: absolute;
    display: block;
    padding: 5px 20px;
    top: -1.3rem;
    left: 50%;
    transform: translateX(-50%);
    background: #e7e7e7;
  }
}

.socials {
  @include prefixed(display, 'flex');
  @include prefixed(justify-content, 'center');

  svg {
    cursor: pointer;
    margin: 0 !important;
  }
}
