@import "../../../../../../styles/imports/";

.kitchen-order-ticket {
  width: 100%;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  background-color: $internetColor;

  @include prefixed(border-radius, $border-radius);
  padding: 2rem 2rem 4rem;

  &.order-taken-on-spot {
    background-color: $shopColor;
  }

  &.order-for-later {
    background-color: $laterColor !important;
  }

  .title-and-customer {
    @include prefixed(display, flex);
    @include prefixed(align-items, center);
    @include prefixed(justify-content, space-between);

    h6 {
      font-size: 3rem;
    }

    .ticket-order-customer {
      font-style: italic;
    }
  }

  .order-item-list {
    list-style-type: none;

    .order-menus-list {
      list-style-type: none;
      margin-bottom: 2rem;

      @include prefixed(display, flex);
      @include prefixed(align-items, flex-start);
      @include prefixed(flex-wrap, wrap);
    }
  }
}
