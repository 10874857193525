@import "../../../../../../../../styles/imports/";

.quantity-fields {
  font-size: 1.5rem;

  .field-row {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    @include prefixed(align-items, center);

    margin-bottom: 2rem;
  }

  button {
    width: 100%;
    padding: 1rem 3rem;
    @include prefixed(border-radius, $border-radius);
    background-color: $medium-green;
    border: none;
    font-size: 1.5rem;
    color: $white;

    &:hover {
      background-color: $dark-green;
      cursor: pointer;
    }
  }

  .delete-cross {
    color: $red-error;
    font-weight: bold;
    cursor: pointer;
    font-size: 2.5rem;
  }

  label {
    margin-right: 1rem;

    span {
      margin-right: 1rem;
    }

    input {
      padding: 0.5rem 1rem;
      width: 9rem;
      font-size: 1.6rem;
      @include prefixed(border-radius, $border-radius);
    }
  }
}
