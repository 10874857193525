$grey: #2b343d;
$dark-grey: #202830;
$white: #fff;
$dark-green: #40860c;
$medium-green: #79990a;
$light-green: #b4c110;
$light-grey: #29363f;
$yellow: #f3c634;
$red-error: #ca5151;

$offerColor: #da4167;
$menuColor: #514663;
$snackColor: #cba135;
$appetizerColor: #6fd08c;
$mealColor: #ffb7c3;
$accompanimentColor: #d7d0c8;
$sandwichColor: #da4167;
$dessertColor: #548687;
$drinkColor: #49111c;
$jarColor: #f2f4f3;
$frozenColor: #5899e2;
$seasoningColor: #ef8354;
$jamColor: #c179b9;
$sweetColor: #e6c79c;

$internetColor: #5899e2;
$shopColor: #e6c79c;
$laterColor: #ef8354;

$colors: $offerColor, $menuColor, $snackColor, $appetizerColor, $mealColor,
  $accompanimentColor, $dessertColor, $drinkColor, $jarColor, $frozenColor,
  $seasoningColor, $jamColor, $sweetColor;
