@import "../../styles/imports";

.navbar {
  height: $navbar-height;
  width: 100%;
  background-color: $grey;
  padding: 1rem 0rem 1rem 5rem;

  @include prefixed(display, "flex");
  @include prefixed(justify-content, "space-between");
  @include prefixed(align-items, "center");

  .navbar-logo {
    display: block;
    height: 4rem;
  }
}
