@import "../../../../../../styles/imports/";

.admin-product-list {
  margin-top: 3rem;

  .admin-product-menu-nav {
    list-style-type: none;
    @include prefixed(display, flex);
    @include prefixed(flex-wrap, wrap);
    @include prefixed(justify-content, center);
    margin: 0 auto;

    li {
      cursor: pointer;
      padding: 2rem 3rem;
      font-size: 1.6rem;
      font-style: italic;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: $dark-green;
      }
    }
  }

  h6 {
    font-size: 1.8rem;
    margin: 2rem 0;
  }

  .product-item-list {
    list-style-type: none;
  }
}
