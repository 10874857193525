@import '../../../../styles/imports/';

.emailing-page {
  padding: 5rem 3rem;
  flex: 1;

  h2 {
    font-size: 2.5rem;
  }

  .feedback-message-container {
    width: 100%;
    background-color: $medium-green;
    color: $white;
    padding: 2rem;
    text-align: center;
    margin: 2rem 0;
    @include prefixed(border-radius, $border-radius);
  }

  .emailing-container {
    margin: 4rem auto;

    button {
      .loader {
        span {
          background-color: $white !important;
        }
      }
    }

    .mail-subject {
      margin: 4rem 0;

      .error-message {
        color: $red-error;
        font-weight: bold;
        font-size: 2rem;
      }
    }
  }
}
