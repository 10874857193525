@import "../../../../styles/imports/";

.manage-pending-orders-container {
  min-height: 100%;
  background-color: $light-grey;
  position: relative;

  .manage-pending-order-list {
    list-style-type: none;
    @include prefixed(display, flex);
    @include prefixed(flex-wrap, wrap);
    @include prefixed(justify-content, flex-start);

    padding: 2rem 4rem;
  }

  .no-pending-orders {
    text-align: center;
    color: $white;
    font-size: 2.5rem;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
