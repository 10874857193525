@import "../../../../../../../../styles/imports/";

.ticket-order-menu {
  margin-bottom: 2rem;
  .ticket-order-menu-type {
    font-style: italic;
    margin-bottom: 0.5rem;
  }

  .ticket-order-menu-items {
    margin-left: 2rem;

    div {
      margin-bottom: 0.3rem;
    }

    .order-menu-note {
      color: $red-error;
      font-style: italic;
    }
  }

  .menu-extra {
    color: $red-error;
    margin-right: 5px;
    font-weight: bold;
  }
}
