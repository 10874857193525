@import "../../styles/imports/";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;

    &.noverflow {
      overflow: unset;
    }

    &.large-modal {
      max-width: 130rem;
    }

    &.no-padding {
      padding: 0;
    }

    @include prefixed(transform, "translate(-50%, -50%)");
    background-color: $white;
    width: 95%;
    padding: 3rem;
    max-width: 60rem;
    height: 100;
    // min-height: 80rem;
    pointer-events: all;
    max-height: 95vh;
    overflow: auto;
    border-radius: $border-radius;
    font-family: $roboto;

    &.cart-remove-modal {
      font-size: 1.6rem;
      text-align: center;
      min-height: 0;

      .item-name {
        color: $dark-green;
      }

      .cart-remove-choice-btns {
        @include prefixed(display, "flex");
        margin: 2rem 0 0;

        button {
          width: 50%;
          border: none;
          @include prefixed(border-radius, $border-radius);
          padding: 1rem;
          color: $white;
          font-family: $roboto;
          font-size: 1.6rem;
          cursor: pointer;

          &:first-child {
            margin-right: 1rem;
          }
          &.choice-btn-no {
            background-color: $dark-green;

            &:hover {
              background-color: darken($dark-green, 10%);
            }
          }

          &.choice-btn-yes {
            background-color: $red-error;

            &:hover {
              background-color: darken($red-error, 10%);
            }
          }
        }
      }
    }
  }
}
