@import "./imports/";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;

  font-family: $roboto;
}

html {
  font-size: 10px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $montserrat;
  letter-spacing: 0.1rem;
}

.App {
  @include prefixed(display, flex);
  @include prefixed(flex-direction, column);
  @include prefixed(align-self, flex-start);

  min-height: 100vh;
}

.main {
  width: 100%;
  height: calc(100vh - $navbar-height);
}

.kitchen-main {
  height: 100vh;
  width: 100%;
  background: $light-grey;
}
