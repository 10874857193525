@import '../../styles/imports/';

.feedback-message {
  @include prefixed(border-radius, $border-radius);
  @include prefixed(transform, 'translateX(-50%)');

  position: fixed;
  padding: 10px;
  top: -10rem;
  @include prefixed(animation, 'fallin 0.35s ease-out forwards');
  left: 50%;
  z-index: 999;
  padding: 1.5rem 3rem;
  color: $white;
  font-size: 2rem;

  &.feedback-message-success {
    background-color: $dark-green;
  }

  &.feedback-message-danger {
    background-color: $red-error;
  }

  &.feedback-message-warning {
    background-color: darken($yellow, 20%);
  }
}
