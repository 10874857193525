@import '../../styles/imports/';

.form-btn {
  display: block;
  width: 100%;
  padding: 1.5rem 3rem;
  border-radius: 3px;
  margin: 2.5rem auto 0;
  color: #fff;
  background-color: $medium-green;
  font-size: 1.8rem;
  font-family: $roboto;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  cursor: pointer;

  &:disabled {
    background-color: $light-grey;
    cursor: default;

    &:hover {
      background-color: $light-grey;
    }
  }

  &:hover {
    background-color: $dark-green;
  }
}
