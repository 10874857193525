@import "../../../../../../../../styles/imports";

.reward-options {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;

  .reward-option-item {
    flex: 1 1 calc(33.333% - 1rem);
    padding: 1.5rem 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s, border-color 0.3s;
    background: $white;
    color: black;

    &.selected {
      background-color: $dark-green;
      color: $white;
    }
  }
}
