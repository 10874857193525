@import "../../../../styles/imports/";

.jiMOeR {
  height: auto !important;
  margin-bottom: 2rem;
}
.customer-select {
  margin: 2rem 0 1rem;

  h2 {
    margin-bottom: 2rem;
  }

  .customer-already-valid {
    font-size: 1.8rem;
    text-align: center;
  }

  .points-and-sign-in {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    @include prefixed(align-items, center);
    margin-top: 9rem;

    .customer-loyalty-points {
      font-size: 1.4rem;

      .remove-reward-button {
        background-color: $red-error;

        &:hover,
        &:focus {
          background-color: darken($red-error, 10%);
        }
      }

      span {
        font-size: 2rem;
        color: $dark-green;
        font-weight: bold;
      }

      button {
        padding: 1rem 1.5rem;
        margin-left: 1.5rem;
        @include prefixed(border-radius, $border-radius);
        border: none;
        cursor: pointer;
        background-color: $medium-green;
        color: $white;

        &:hover,
        &:focus {
          background-color: $dark-green;
        }
      }
    }
  }

  input[type="text"] {
    @include prefixed(border-radius, $border-radius);
    // @include prefixed(transition, 0.5s);

    margin: 2rem 0;
    width: 100%;
    padding: 1.5rem 3rem;

    border: solid 1px $medium-green;
    border-bottom: 5px solid $medium-green;

    font-size: 2rem;

    &:focus {
      outline: none !important;
      border: solid 3px $medium-green;
      border-bottom: 5px solid $medium-green;
    }

    &::placeholder {
      color: rgb(172, 172, 172);
    }
  }

  .button-container {
    @include prefixed(display, flex);
    @include prefixed(justify-content, "flex-end");

    button {
      border: none;
      background-color: $medium-green;
      padding: 2rem;
      cursor: pointer;
      @include prefixed(border-radius, $border-radius);
      color: $white;
      user-select: none;

      &:hover,
      &:focus {
        background-color: $dark-green;
      }
    }
  }
}
