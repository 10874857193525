@import "../../../../../../../../styles/imports/";

.reward-choices {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .reward-choice-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, border-color 0.3s;

    &:hover:not(.disabled) {
      background: #f0f0f0;
    }

    &.selected {
      border-color: #4caf50;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .reward-image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 4px;
      margin-right: 1rem;
    }

    .reward-details {
      display: flex;
      flex-direction: column;

      .reward-name {
        font-size: 1.8rem;
        font-weight: bold;
      }

      .reward-cost {
        font-size: 1.4rem;
        color: #777;
      }
    }
  }
}
