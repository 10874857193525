@import "../../../../styles/imports/";

.order-menu {
  font-size: 1.4rem;
  margin: 2rem 0;

  .order-menu-type-and-price {
    @include prefixed(display, flex);
    @include prefixed(justify-content, "space-between");
  }

  .order-menu-items {
    margin-left: 2rem;
    margin-top: 1rem;

    div {
      margin: 0.75rem 0;
    }

    .order-menu-note {
      color: $yellow;
      font-style: italic;
    }
  }

  .order-menu-delete-button {
    color: $red-error;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 1.5rem;
    @include prefixed(display, flex);
    @include prefixed(justify-content, "flex-end");

    // margin-left: 2rem;
    cursor: pointer;
  }

  .menu-extra {
    color: $red-error;
    margin-right: 5px;
  }
}
