@import "../../../../styles/imports/";

.order-recap-ticket {
  @include prefixed(display, "flex");
  @include prefixed(justify-content, "space-between");
  @include prefixed(flex-direction, "column");
  height: 100%;
  color: $white;

  h4 {
    text-align: center;
    padding: 2rem 0 0 0;
    margin: 0;
    height: 7%;
    font-size: 2rem;
  }

  .order-recap-ticket-content {
    height: 85%;
    padding: 1rem 2.5rem;
    overflow: auto;

    .no-product {
      text-align: center;
      //   font-weight: bold;
      font-size: 1.4rem;
    }
  }
}
