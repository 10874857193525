@import "../../../../../../styles/imports/";

.ticket-item {
  user-select: none;

  font-size: 1.5rem;
  margin: 2.5rem 0;

  .ticket-item-name-and-unit-price {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    font-size: 1.4rem;
    margin-bottom: 0.75rem;

    .ticket-item-name {
      @include prefixed(display, flex);

      .product-quantity {
        display: block;
        margin-right: 0.5rem;
      }

      .product-weight {
        margin-left: 0.5rem;
        font-size: 1.2rem;
      }
    }

    .ticket-item-unit-price {
      font-style: italic;

      .old-price {
        text-decoration: line-through;
        font-size: 1.2rem;
        margin-right: 0.5rem;
      }
    }
  }

  .ticket-item-quantity-and-price {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);

    font-size: 1.4rem;
    margin: 0 0.75rem;

    .order-item-prices {
      font-size: 1.8rem;
      .sale-price {
        color: $yellow;
      }

      .old-price {
        font-size: 1.4rem;
        text-decoration: line-through;
        margin-right: 0.5rem;
      }
    }
  }
}
