@import "../../../../../../../../styles/imports/";

.card-item-price {
  font-size: 2.2rem;
  display: inline-block;
  text-align: center;

  .in-sale {
    color: $yellow;
    font-weight: bold;
  }
}
