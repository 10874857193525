@import "../../../../../../../../styles/imports/";

.ticket-order-item {
  .ticket-order-item-name-and-quantity {
    @include prefixed(display, flex);
    @include prefixed(align-items, baseline);

    .ticket-order-item-title {
      margin-right: 1rem;
    }

    .dots {
      flex: 1;
      overflow: hidden;
    }

    .ticket-order-item-cart-quantity {
      margin-left: 1rem;
    }
  }
}
