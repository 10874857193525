@import "../../styles/imports/";

.menu-return-button {
  position: fixed;
  bottom: 5rem;
  left: 10rem;

  @include prefixed(transform, "translateY(-65%)");

  height: 5rem;
  width: 5rem;

  @include prefixed(border-radius, $border-radius);
  @include prefixed(box-shadow, "2px 2px 2px #40860c");
  background-color: $medium-green;

  color: $white;

  cursor: pointer;

  &:hover {
    background-color: $light-green;

    svg {
      transition: all 0.5s ease;
      position: relative;
      bottom: 5px;
    }
  }
}

// @media screen and (max-width: 1100px) {
//   .menu-return-button {
//     display: none;
//   }
// }
