@import '../../../../styles/imports/';

.admin-products {
  padding: 5rem 3rem;
  flex: 1;
  height: 100%;
  overflow: auto;

  h2 {
    font-size: 2.5rem;
  }

  .admin-button {
    width: 50rem;
    margin: auto;

    button {
      height: 7.5rem;
    }
  }
}
