@import "../../../../../../styles/imports/";

.search-bar-custom {
  position: relative;
}

.wrapper {
  padding: 1rem 2rem;
  //   margin: 2rem
  position: relative !important;

  svg {
    margin: 0 0.5rem 0 0;
  }
}

.search-result-line {
  height: 5rem;
  @include prefixed(display, flex);
  @include prefixed(justify-content, space-between);
  padding: 1.5rem 3rem;

  font-size: 2rem;
}
