@import "../../../../../../styles/imports/";

.payment-icon {
  padding: 1rem;
  width: 20%;
  @include prefixed(border-radius, 1rem);
  user-select: none;

  &.selected {
    border: solid 2px $medium-green;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 7.5rem;
  }
  h6 {
    text-align: center;
    font-size: 1.8rem;
  }
}
