@import "../../../../styles/imports/";

.order-checkout-ticket {
  @include prefixed(display, flex);
  @include prefixed(flex-direction, column);

  width: 30%;
  min-width: 35rem;
  height: 100%;
  background-color: $light-grey;

  position: relative;

  padding: 3rem 2rem 0;
  color: $white;

  h2 {
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .date {
    text-align: center;
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }

  .customer-name {
    text-align: center;
    font-size: 1.4rem;
    margin: 1rem 0;
  }

  .reward-item {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    font-size: 1.4rem;
  }

  .order-checkout-ticket-current-order {
    @include prefixed(display, flex);
    @include prefixed(flex-direction, column);

    height: 100%;

    .current-checkout-ticket-cart-items {
      height: 80%;
      overflow: auto;
      padding-right: 1.5rem;
      max-height: 72rem;
    }
  }
}
