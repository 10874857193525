@import "../../../../styles/imports/";

.navbar-menu {
  list-style-type: none;
  height: $navbar-height;
  @include prefixed(display, flex);
  @include prefixed(align-items, "center");

  .navbar-link {
    height: 100%;
    color: $white;
    font-size: 2rem;
    padding: 1rem 3rem;
    @include prefixed(display, flex);
    @include prefixed(align-items, "center");
    border-left: grey 1px solid;

    &.active-link {
      background-color: lighten($grey, 10%);
    }
  }

  .clock {
    font-size: 3.5rem;
  }
}
