@import "../../styles/imports/";

.send-ticket-confirm-message {
  font-size: 2rem;
  text-align: center;
  color: $grey;

  position: relative;

  .buttons {
    margin: 2rem 0;
    @include prefixed(display, flex);

    button {
      color: white;
      font-size: 2rem;
      width: 50%;
      margin: 0 1rem;
      display: block;
      border: none;
      padding: 2rem 3rem;
      @include prefixed(border-radius, $border-radius);
      cursor: pointer;

      &.confirm-button {
        background-color: $medium-green;

        &:hover,
        &:focus {
          background-color: $dark-green;
        }
      }

      &.decline-button {
        background-color: $red-error;

        &:hover,
        &:focus {
          background-color: darken($red-error, 10%);
        }
      }
    }
  }
}

.send-ticket-to-customer-container {
  position: absolute;
  top: 1rem;
  right: 1rem;

  button {
    width: 5rem;
    height: 5rem;
    cursor: pointer;

    @include prefixed(border-radius, $border-radius);
    border: none;

    &:hover,
    &:focus {
      background-color: darken(#e7e7e7, 10%);
    }

    img {
      width: 4rem;
    }
  }
}
