@import "../../../../styles/imports/";

.order-total {
  font-size: 1.4rem;
  margin-bottom: 1rem;

  .first-line {
    @include prefixed(display, "flex");
    @include prefixed(justify-content, "space-between");
    @include prefixed(align-items, "center");
    padding: 0 1rem;

    .total-without-reduction,
    .reduction {
      width: 40%;
      @include prefixed(display, flex);
      @include prefixed(justify-content, "space-between");
    }
  }

  .fidelity-points {
    text-align: center;
    font-style: italic;
  }

  hr {
    margin-bottom: 1rem;
  }

  h3 {
    text-align: center;
    font-size: 1.8rem;
  }

  p {
    margin: 0.75rem 0;
  }

  .total {
    @include prefixed(display, flex);
    @include prefixed(justify-content, "space-between");
    font-size: 2rem;
    padding: 0 1rem;
    .total-amount {
      color: $yellow;
      font-weight: bold;
    }
  }
}
