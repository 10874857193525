@import "../../../../styles/imports/";

.previous-button {
  background-color: $medium-green;
  color: $white;
  padding: 1.5rem 3rem;
  // margin-bottom: 1.rem;
  display: block;
  max-width: 32rem;
  text-align: center;

  font-size: 1.6rem;
  cursor: pointer;

  @include prefixed(border-radius, $border-radius);

  &:hover,
  &:focus {
    background-color: $dark-green;
  }
}
