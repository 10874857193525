@import '../../../../../../../../styles/imports/';

.admin-order-item {
  position: relative;
  background-color: $internetColor;
  //   color: $white;
  font-size: 1.6rem;
  width: 45rem;

  margin: 2rem;

  &.admin-order-on-spot {
    background-color: $shopColor;
  }

  padding: 2rem 3rem;
  @include prefixed(border-radius, $border-radius);

  h6,
  .customer-name {
    font-size: 1.4rem;
  }

  .admin-order-item-menus,
  .admin-order-item-products {
    margin: 1.5rem 0;
    list-style-type: none;
  }

  hr {
    margin: 1rem 0;
    background-color: $dark-grey;
    height: 2px;
    border: none;
  }

  .admin-order-item-total {
    margin: 1rem 0;
    font-size: 1.8rem;
  }
}
