@import "../../../../../../styles/imports/";

.order-to-manage {
  @include prefixed(display, flex);
  @include prefixed(flex-direction, column);
  @include prefixed(justify-content, "space-between");
  background-color: $internetColor;
  cursor: pointer;

  width: 18%;
  height: 10rem;
  margin: 2rem;

  font-weight: bold;

  @include prefixed(border-radius, $border-radius);

  &.order-taken-on-spot {
    background-color: $shopColor;

    &:hover,
    &:focus {
      background-color: darken($shopColor, 10%);
    }
  }

  &:hover,
  &:focus {
    background-color: darken($internetColor, 10%);
  }

  .manage-order-infos {
    padding: 1rem;
  }

  .manage-order-payment {
    text-align: center;

    font-size: 1.4rem;

    .order-paid {
      padding: 1rem;
      background-color: $medium-green;
      @include prefixed(border-radius, 0 0 $border-radius $border-radius);
    }

    .order-not-paid {
      padding: 1rem;
      background-color: $red-error;
      @include prefixed(border-radius, 0 0 $border-radius $border-radius);
    }
  }

  span {
    display: block;
  }

  .manage-order-number {
    font-size: 2rem;
    text-align: center;
  }

  .manage-order-total {
    text-align: center;
    font-size: 1.6rem;
    margin: 1rem 0 0;
  }
}
