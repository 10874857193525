@import '../../../../../../../../styles/imports/';

.created-menu-container {
  margin: 3rem 0;
  h2 {
    font-size: 1.8rem;
  }

  .created-menu {
    @include prefixed(display, flex);
    @include prefixed(flex-wrap, wrap);

    .sauce {
      font-size: 2rem;
      @include prefixed(display, flex);
      @include prefixed(align-items, center);
    }
  }
  .validate-menu-button {
    display: block;
    border: none;
    background-color: $medium-green;
    color: $white;
    padding: 2rem 3rem;
    font-size: 2rem;
    @include prefixed(border-radius, $border-radius);

    &:hover,
    &:focus {
      background-color: $dark-green;
    }

    &:disabled {
      background-color: rgba($light-grey, 0.5);
      cursor: not-allowed;
    }
  }
}
