@import "../../../../../../../styles/imports/";

.change-due-container {
  font-size: 2rem;

  h6 {
    text-align: center;
    margin-bottom: 2rem;
  }

  input {
    display: block;
    width: 100%;
    height: 4rem;
    padding: 1.5rem 2rem;
    font-size: 1.8rem;
  }

  button {
    width: 100%;
    height: 3rem;
    background-color: $medium-green;
    color: $white;
    border: none;
    font-weight: bold;
    margin-top: 2rem;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $dark-green;
    }
  }

  .change {
    text-align: center;
    margin: 1.5rem 0 0;
  }
}
