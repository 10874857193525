@import "../../styles/imports/";

.order-recap-component {
  position: absolute;
  top: $navbar-height;
  right: 0;
  height: 6rem;

  .order-recap {
    height: 100%;
    @include prefixed(display, flex);

    height: 6rem;
    width: 39.656rem;

    .order-recap-validate {
      @include prefixed(display, flex);
      @include prefixed(align-items, center);
      @include prefixed(justify-content, "center");
      @include prefixed(border-radius, 0 0 0 $border-radius);

      width: 25rem;
      color: white;
      text-align: center;
      font-size: 2rem;
      height: 6rem;
      background-color: $medium-green;

      &:hover,
      &:active {
        cursor: pointer;
        background-color: $dark-green;
        color: $white;
      }

      .double-arrow-icon {
        font-size: 2.4rem;
        margin-left: 0.2rem;
      }
    }

    .order-recap-price {
      @include prefixed(display, flex);
      @include prefixed(align-items, center);
      @include prefixed(justify-content, "center");
      background-color: $yellow;
      width: 15rem;

      &:hover,
      &:active {
        cursor: pointer;
        background-color: darken($yellow, 10%);
        color: $white;
      }

      height: 100%;
      font-size: 2.4rem;
      color: $dark-grey;
      font-weight: bold;

      .shopping-bag-icon {
        font-size: 2.4rem;
        margin-right: 0.2rem;
      }
    }
  }

  // @include prefixed(display, flex);
  // @include prefixed(flex-direction, column);
  // @include prefixed(justify-content, "space-between");

  // .order-recap {
  //   padding: 3rem 2rem 0;
  //   color: $white;
  //   height: 80%;

  //   @include prefixed(display, flex);
  //   @include prefixed(flex-direction, column);

  //   h2 {
  //     font-size: 2rem;
  //     text-align: center;
  //     margin-bottom: 1.5rem;
  //   }

  //   .no-product-message {
  //     font-size: 1.6rem;
  //     text-align: center;
  //   }

  //   .order-recap-current-order {
  //     @include prefixed(display, flex);
  //     @include prefixed(flex-direction, column);
  //     @include prefixed(justify-content, "space-between");

  //     height: 100%;

  //     .current-order-cart-items {
  //       height: 75%;
  //       max-height: 65rem;
  //       overflow: auto;
  //       padding-right: 1.5rem;

  //       .reward-item {
  //         @include prefixed(display, flex);
  //         @include prefixed(justify-content, space-between);
  //         font-size: 1.4rem;
  //       }
  //     }
  //   }
  // }
}
