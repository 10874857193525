@import "../../../../../../../../styles/imports/";

.menu-products-category {
  margin: 2rem 0;

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;

    .title-extra {
      color: $red-error;
      font-weight: bolder;
    }
  }

  .menu-product-list {
    @include prefixed(display, flex);
    @include prefixed(flex-wrap, wrap);
  }
}
