@import "../../../../styles/imports/";

.admin-orders {
  padding: 5rem 3rem;
  flex: 1;
  height: 100%;
  overflow: auto;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }

  .end-date {
    margin: 0 2rem;
  }

  .date-text {
    font-size: 2rem;
    margin-right: 1rem;
  }

  .get-orders-button,
  .interval-button {
    display: block;
    margin: 2rem 0;
    padding: 1.5rem 3rem;
    @include prefixed(border-radius, $border-radius);
    border: none;
    background-color: $medium-green;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: 2rem;

    &:focus,
    &:hover {
      background-color: $dark-green;
    }
  }

  .interval-button {
    background-color: $light-grey !important;
  }

  .no-order-day {
    font-size: 2rem;
  }
}

.react-date-picker__wrapper {
  button svg {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}
.react-date-picker {
  width: 30rem;

  .react-date-picker__inputGroup {
    font-size: 3rem;
    width: auto;
  }
}

.react-date-picker__calendar {
  width: 600px !important;
  font-size: 3rem;

  .react-calendar {
    width: 600px !important;

    .react-calendar__navigation {
      .react-calendar__navigation__arrow {
        font-size: 3rem;
      }
      .react-calendar__navigation__label {
        font-size: 2.5rem;
      }
    }
  }
}
