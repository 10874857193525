@import "../../../../styles/imports";

.admin-bar {
  height: 100%;
  background-color: $dark-grey;
  width: 35rem;
  position: relative;

  .admin-bar-menu {
    list-style-type: none;

    .admin-bar-menu-item {
      @include prefixed(display, flex);
      @include prefixed(align-items, center);
      @include prefixed(justify-content, center);

      width: 100%;
      cursor: pointer;
      color: $white;
      font-size: 2rem;
      height: 8rem;

      &.active {
        background-color: lighten($grey, 10%);
      }
    }
  }

  .logout {
    color: $white;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    border: solid 1px $white;
    border-radius: $border-radius;
    padding: 1.5rem 2.5rem;

    &:hover {
      cursor: pointer;
      background-color: $white;
      color: $dark-grey;
    }
  }
}
