@import "../../../../../../styles/imports/";

.kitchen-ticket-order-menu {
  margin: 0 8rem 1.5rem 0;

  .ticket-order-menu-type {
    font-style: italic;
    margin-bottom: 0.5rem;
  }

  .ticket-order-menu-items {
    margin-left: 1.5rem;

    div {
      margin-bottom: 0.3rem;
    }

    .order-menu-note {
      color: $red-error;
      font-style: italic;
    }
  }

  .menu-extra {
    margin-right: 5px;
    font-weight: bold;
    font-size: 2.6rem;
  }
}
