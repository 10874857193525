@import "../../../../../../styles/imports/";

.loyalty-products {
  padding: 2rem;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  .loyalty-products-pickup {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .points-tier,
    .reward-choices,
    .reward-options,
    .reward-menu-options {
      // background: #ffffff;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      margin: 1rem 0;
    }
  }

  .loyalty-recap {
    margin-top: 2rem;
    padding: 1rem;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    text-align: center;

    h3 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    .loyalty-choice {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      p {
        font-size: 1.4rem;
      }

      button {
        padding: 0.8rem 1.6rem;
        background: #4caf50;
        color: #ffffff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: #45a049;
        }
      }
    }
  }
}
