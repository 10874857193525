@import "../../../../../../../../styles/imports/";

.points-tier {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;

  .tier-item {
    font-size: 1.6rem;
    padding: 1.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;

    &.active {
      background: $dark-green;
      color: $white;

      &:hover {
        background: $dark-green;
        color: $white;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    // &:hover:not(.disabled) {
    //   background: #f0f0f0;
    // }
  }
}
