@import "../../../../../../styles/imports/";

.clock {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  color: $white;
  font-weight: bold;
  margin: 1rem 0 0;
  padding-bottom: 1rem;
  border-bottom: solid 2px $white;
}
