@import '../../../../../../../../styles/imports/';

.chili-level {
  position: absolute;
  bottom: 0;
  left: 0;

  .chili-normal {
    height: 5rem;
  }

  .chili-small {
    height: 2.5rem;
  }

  .grey-chili {
    opacity: 0.35;
  }
}
