@import '../../../../styles/imports/';

.order-paid-confirm-message {
  font-size: 2rem;
  text-align: center;

  .buttons {
    margin: 2rem 0;
    @include prefixed(display, flex);

    button {
      color: white;
      font-size: 2rem;
      width: 50%;
      margin: 0 1rem;
      display: block;
      border: none;
      padding: 2rem 3rem;
      @include prefixed(border-radius, $border-radius);
      cursor: pointer;

      &.confirm-button {
        background-color: $medium-green;

        &:hover,
        &:focus {
          background-color: $dark-green;
        }
      }

      &.decline-button {
        background-color: $red-error;

        &:hover,
        &:focus {
          background-color: darken($red-error, 10%);
        }
      }
    }
  }
}
