@mixin prefixed($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};

  #{$property}: #{$value};
}

@mixin setColorAndHover($baseColor) {
  background-color: $baseColor;
  &:hover,
  &:focus {
    background-color: darken($baseColor, 10%);
  }
}
