@import "../../../../../../styles/imports/";

.menu-tab-container {
  width: 100%;
  padding: 0 2.5rem;
  h1 {
    font-size: 2.5rem;
  }

  .drink-or-dessert-buttons {
    @include prefixed(display, flex);
    @include prefixed(justify-content, "space-around");

    button {
      background-color: $medium-green;
      cursor: pointer;
      color: $white;
      padding: 2rem 3rem;
      font-size: 2rem;
      border: none;
      @include prefixed(border-radius, $border-radius);
    }
  }

  .extra-button {
    background-color: $yellow;
    cursor: pointer;
    color: white;
    text-shadow: 0rem 0rem 0.2rem black;
    font-weight: bold;
    padding: 2rem 3rem;
    margin: 0 auto;
    display: block;
    font-size: 2rem;
    border: none;
    @include prefixed(border-radius, $border-radius);
  }

  .snack-button {
    background-color: $red-error;
    cursor: pointer;
    color: white;
    text-shadow: 0rem 0rem 0.2rem black;
    font-weight: bold;
    padding: 2rem 3rem;
    margin: 0 auto;
    display: block;
    font-size: 2rem;
    border: none;
    @include prefixed(border-radius, $border-radius);

    &.noSnack {
      background-color: $dark-green;
    }
  }

  .add-note {
    margin-top: 2.5rem;
  }
}
