@import "../../../../../../styles/imports/";

.category-card {
  flex-grow: 1;
  color: $white;
  text-shadow: 0px 1px 3px black;
  font-size: 1.6rem;
  cursor: pointer;

  @include prefixed(display, flex);
  @include prefixed(align-items, center);
  @include prefixed(justify-content, center);

  &.active-category {
    width: 110%;
    @include prefixed(border-radius, 0 $border-radius $border-radius 0);
  }

  &.offer {
    @include setColorAndHover($offerColor);
  }
  &.menu {
    @include setColorAndHover($menuColor);
  }
  &.snack {
    @include setColorAndHover($snackColor);
  }
  &.meal {
    @include setColorAndHover($mealColor);
  }
  &.accompaniment {
    @include setColorAndHover($accompanimentColor);
  }
  &.sandwich {
    @include setColorAndHover($sandwichColor);
  }
  &.dessert {
    @include setColorAndHover($dessertColor);
  }
  &.drink {
    @include setColorAndHover($drinkColor);
  }
  &.jar {
    @include setColorAndHover($appetizerColor);
  }
  &.frozen {
    @include setColorAndHover($frozenColor);
  }
  &.seasoning {
    @include setColorAndHover($seasoningColor);
  }
  &.jam {
    @include setColorAndHover($jamColor);
  }
  &.sweet {
    @include setColorAndHover($sweetColor);
  }
}
