@import '../../../../../../../../styles/imports';

.sandwich-sauce-category {
  label {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: $montserrat;
    letter-spacing: 0.1rem;
    font-weight: bold;
  }

  select {
    font-family: $roboto;
    font-size: 2rem;

    option {
      font-size: 1rem;
      zoom: 3;
    }
  }
}
