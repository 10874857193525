@import "../../../../styles/imports/";

.kitchen-orders {
  width: 75%;
  overflow: auto;

  .kitchen-order-list {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
  }

  .no-pending-orders {
    color: $white;
    text-align: center;
    font-size: 3.2rem;
    margin: 10rem;
  }
}
