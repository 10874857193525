@import "../../styles/imports/";

.ticket-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
}

.ticket-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;

  .ticket-modal {
    position: absolute;
    left: 50%;

    @include prefixed(transform, "translateX(-50%)");
    background-color: $dark-grey;
    width: 50rem;
    pointer-events: all;
    height: 100vh;
    overflow: auto;
    font-family: $roboto;
  }
}
