@import '../../../../../../../../../../styles/imports/';

.admin-order-item-product {
  margin-bottom: 1rem;

  .quantity-and-price {
    @include prefixed(display, flex);
    @include prefixed(align-items, baseline);

    .cart-quantity {
      margin-right: 1rem;
    }

    .dots {
      flex: 1;
      overflow: hidden;
    }

    .price {
      margin-left: 1rem;
    }
  }

  .old-price {
    text-decoration: line-through;
  }
}
