@import "../../../../../../styles/imports/";

.order-ticket {
  // width: 28%;
  max-width: 30%;
  flex: 1 0 30%;
  margin: 0 2rem 2rem;
  font-size: 2rem;
  background-color: $internetColor;

  @include prefixed(border-radius, $border-radius);
  padding: 2rem 2rem 4rem;

  &.order-taken-on-spot {
    background-color: $shopColor;
  }

  &.order-for-later {
    background-color: $laterColor !important;
  }

  .title-and-customer {
    text-align: center;
    margin-bottom: 2rem;

    h6 {
      font-size: 2rem;
    }

    .ticket-order-customer {
      font-size: 1.4rem;
      font-style: italic;
    }

    .time {
      font-size: 1.4rem;
    }
  }

  .order-item-list {
    list-style-type: none;

    .reward-item {
      @include prefixed(display, flex);
      @include prefixed(justify-content, space-between);
      // font-size: 1.6rem;

      .dots {
        flex: 1;
        overflow: hidden;
      }
    }
  }

  hr {
    margin: 2rem 0;
    height: 0.2rem;
    border: none;
    background-color: $dark-grey;
  }

  .order-total {
    text-align: right;
  }
}
