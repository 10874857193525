@import '../../styles/imports/';

.homebar {
  @include prefixed(display, flex);
  @include prefixed(justify-content, 'space-between');
  @include prefixed(align-items, 'center');

  padding: 0 3rem;

  width: 100%;
  height: calc($navbar-height + 3rem);
  background-color: $dark-grey;

  .title-and-caption {
    @include prefixed(display, flex);
    @include prefixed(align-items, 'center');
  }

  h1 {
    color: $white;
    text-transform: uppercase;
    margin-right: 4rem;
  }

  .take-order-button {
    @include prefixed(display, flex);
    @include prefixed(align-items, 'center');
    color: $white;
    cursor: pointer;

    span {
      font-size: 2rem;
      margin-right: 1rem;
    }

    .add-order-button {
      font-size: 4rem;
    }

    &:hover,
    &:focus {
      color: $light-green;
    }
  }
}
