@import "../../../../../../../../styles/imports/";

.earnings-container {
  margin: 4rem 5rem 2rem;
  .total-earnings,
  .credit-card-earnings,
  .cash-earnings,
  .apple-pay-earnings,
  .gpay-earnings,
  .paypal-earnings,
  .unpaid {
    @include prefixed(border-radius, $border-radius);
    border: solid 1px $dark-green;
    margin: 1rem 0;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    text-align: center;

    span {
      font-size: 1.8rem;
      color: $dark-green;
      font-weight: bold;
    }
  }

  .unpaid {
    border: solid 1px $red-error;
    span {
      color: $red-error;
    }
  }

  .credit-card-and-cash,
  .apple-and-gpay {
    @include prefixed(display, flex);
  }

  .credit-card-earnings,
  .cash-earnings,
  .apple-pay-earnings,
  .gpay-earnings,
  .paypal-earnings {
    flex: 1;
  }

  .stats {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
  }

  .stats-total-revenue,
  .stats-total-cost,
  .stats-total-margin,
  .stats-margin-percentage {
    flex: 1 0 24%; // Adjust to ensure all items fit on one row
    @include prefixed(border-radius, $border-radius);
    border: solid 1px $dark-green;
    font-size: 1.6rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem 0.5%; // Small margin for spacing
    box-sizing: border-box; // Ensure padding and border are included in the element's total width and height

    &:first-child {
      margin-left: 0; // Remove left margin for the first element
    }

    &:last-child {
      margin-right: 0; // Remove right margin for the last element
    }

    span {
      font-size: 1.8rem;
      margin-left: 0.5rem;
      color: $dark-green;
      font-weight: bold;
    }
  }

  .credit-card-earnings,
  .apple-pay-earnings,
  .gpay-earnings {
    margin-right: 2rem;
  }
}
