@import "../../../../../../../../../../styles/imports/";

.menu-product-card-item {
  @include prefixed(display, "flex");
  flex: 1 0 25%;
  align-self: flex-start;
  max-width: 30%;
  border: solid 0.5rem transparent;
  user-select: none;
  margin: 0.5rem 0.5rem 0.5rem 0;
  border-radius: $border-radius;
  cursor: pointer;

  &.mix-button {
    background-color: $yellow !important;
    text-shadow: black 0px 0px 2px;
    font-size: 1.6rem;

    &.menu-product-active {
      box-sizing: border-box;
      border: solid 1rem $medium-green;
    }

    &:active,
    &:focus,
    &:hover {
      background: darken($yellow, 10%) !important;
    }
  }

  &.mix-snack {
    @include prefixed(display, "flex");
    @include prefixed(align-items, "center");
    background: $light-green;

    h6 {
      font-size: 1.4rem;
      margin-right: 1rem;
      text-align: center;
    }

    div {
      font-size: 1.4rem;
    }
  }

  &.no-product-button,
  &.mix-button {
    height: 10rem;
    font-size: 1.6rem;
    @include prefixed(display, "flex");
    @include prefixed(align-items, "center");
    @include prefixed(justify-content, "center");
    font-weight: bold;

    background: $light-grey;
    color: $white;

    &.menu-product-active {
      box-sizing: border-box;
      border: solid 1rem $medium-green;
    }

    .icon {
      font-size: 2.2rem;
      margin-right: 0.5rem;
    }

    &:active,
    &:focus,
    &:hover {
      background: $dark-grey;
    }
  }

  .menu-product-card-item-name {
    @include prefixed(display, "flex");
    @include prefixed(align-items, "center");

    .menu-card-item-img {
      position: relative;
      padding: 0;
      height: 10rem;

      .main-picture {
        width: 15rem;
        height: 10rem;
      }
    }

    .food-habits-icons {
      @include prefixed(display, "flex");
      @include prefixed(flex-direction, "column");
      padding: 0 1rem;

      img {
        width: 5rem;
        height: 5rem;
      }
    }

    h5 {
      font-size: 1.6rem;
    }
  }

  &.menu-product-active {
    box-sizing: border-box;
    border: solid 0.5rem $medium-green;
  }

  &.menu-product-small {
    max-width: 20% !important;

    &.no-product-div {
      background: #e7e7e7;
      height: 5rem;
      font-size: 1.4rem;
      @include prefixed(display, "flex");
      @include prefixed(align-items, "center");
      @include prefixed(justify-content, "center");
    }

    .menu-product-card-item-name {
      .menu-card-item-img {
        height: 5rem;
        margin-right: 1rem;

        .main-picture {
          width: 7.5rem;
          height: 5rem;
        }
      }
    }

    .food-habits-icons {
      display: none;
    }
  }
}
