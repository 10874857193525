@import "../../styles/imports/";

.color-caption {
  @include prefixed(display, flex);
  @include prefixed(align-items, center);
  font-size: 2rem;
  color: $white;

  .shop {
    margin: 0 0 0 1rem;
    @include prefixed(display, flex);
    @include prefixed(align-items, center);
    border: solid 1px $shopColor;
    padding: 0.75rem 1rem;
    .shop-color {
      margin: 0 1rem;
      height: 3rem;
      width: 3rem;
      border: solid 1px $shopColor;
      @include prefixed(border-radius, $border-radius);
      background-color: $shopColor;
    }
  }

  .internet {
    margin: 0 0 0 0.5rem;
    @include prefixed(display, flex);
    @include prefixed(align-items, center);
    border: solid 1px $internetColor;
    padding: 0.75rem 1rem;
    .internet-color {
      margin: 0 1rem;
      height: 3rem;
      width: 3rem;
      border: solid 1px $internetColor;
      @include prefixed(border-radius, $border-radius);
      background-color: $internetColor;
    }
  }

  .later {
    margin: 0 0 0 0.5rem;
    @include prefixed(display, flex);
    @include prefixed(align-items, center);
    border: solid 1px $laterColor;
    padding: 0.75rem 1rem;
    .later-color {
      margin: 0 1rem;
      height: 3rem;
      width: 3rem;
      border: solid 1px $laterColor;
      @include prefixed(border-radius, $border-radius);
      background-color: $laterColor;
    }
  }
}
