@import '../../styles/imports/';

.formRow {
  display: inline-block;
  width: 100%;

  &.select-error {
    label {
      color: $red-error;
    }

    select {
      border-bottom: 5px solid $red-error;
    }
  }

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  select {
    display: block;
    width: 100%;
    float: left;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 400;
    text-align: left;
    padding: 10px 0px;
    margin: 10px auto;
    border: none;
    border-bottom: 5px solid $light-grey;
    outline: none;
    cursor: pointer;
    font-family: $roboto;

    option:disabled {
      color: $light-grey;
    }
  }
}
