@import "../../../../../../styles/imports/";

.order-products {
  //   color: $white;
  font-size: 1.6rem;
  margin: 2rem;
  padding: 1rem 2rem;
  @include prefixed(border-radius, $border-radius);

  background-color: $shopColor;

  h4 {
    text-align: center;
  }

  ul {
    list-style-type: none;
    width: 100%;
    @include prefixed(display, flex);
    @include prefixed(flex-wrap, wrap);
    @include prefixed(justify-content, space-between);

    li {
      //   flex-grow: 1;
      width: 50%;
      margin-bottom: 0.5rem;

      .quantity {
        // font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }

  .order-reward {
    hr {
      margin: 1rem 0;
    }
    .title {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .content {
      font-style: italic;
    }
  }
}
