@import "../../../../styles/imports/";

.payment-method {
  .payment-method-list {
    margin: 2rem 0 2.5rem;
    @include prefixed(display, flex);
    @include prefixed(justify-content, "space-between");
  }
}

.payment-already-processed {
  font-size: 2rem;
  text-align: center;
  color: $dark-green;
  font-weight: bold;
}
