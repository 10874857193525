@import "../../../../../../../../styles/imports/";

.add-loyalty-reward-form {
  h2 {
    text-align: center;
  }

  .block1,
  .block2 {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    @include prefixed(align-items, stretch);
    margin-bottom: 4rem;

    .left {
      width: 40%;
      margin-right: 2rem;

      .formRow {
        height: 100%;
      }
    }

    .right {
      width: 60%;

      .checkboxes {
        .available-checkbox,
        .availability-level-checkbox,
        .options-checkbox {
          @include prefixed(display, flex);
          @include prefixed(justify-content, space-between);
          @include prefixed(align-items, center);
          font-size: 1.6rem;
          flex-basis: 63%;
          margin-top: 2rem;

          .checkbox {
            @include prefixed(border-radius, $border-radius);
            border: solid 1px $medium-green;

            button {
              font-size: 1.6rem;
              padding: 1.25rem 3rem;
              background: none;
              color: #afafaf;
              border: none;
              transition: background-color 0.3s, color 0.3s;

              &.active {
                background-color: $medium-green;
                color: white;
                font-weight: bold;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
