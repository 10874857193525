@import "../../../../styles/imports/";

.product-list-container {
  width: 100%;
  height: 100%;
}

.product-list {
  @include prefixed(display, flex);
  @include prefixed(flex-wrap, "wrap");
  @include prefixed(align-self, "flex-start");

  padding: 5rem 3rem;

  height: 100%;
  overflow: auto;
  margin-top: 0.2rem;
}
