@import "../../../../../../../../styles//imports/";

@import "../../../../../../../../styles/imports/";

.admin-reward-card {
  width: 100%;
  box-shadow: 0px 0px 2px $light-grey;
  @include prefixed(border-radius, $border-radius);
  @include prefixed(display, "flex");
  @include prefixed(justify-content, "space-between");
  margin: 1rem 0;
  font-size: 1.6rem;
  padding: 0;

  .admin-reward-card-image {
    @include prefixed(display, "flex");
    @include prefixed(align-items, "center");

    .card-item-img {
      width: 9rem;
      border-radius: 5px;
    }
  }

  .admin-reward-card-name {
    @include prefixed(display, "flex");
    @include prefixed(align-items, "center");
    margin-left: 2rem;
    flex: 2;
    cursor: pointer;

    h5 {
      text-align: left;
      font-size: 1.8rem;
    }
  }

  .admin-reward-card-status {
    @include prefixed(display, "flex");
    @include prefixed(align-items, "center");
    @include prefixed(justify-content, "center");
    width: 20%;
    font-weight: bold;
    cursor: pointer;

    span.enabled {
      color: $dark-green;
    }

    span.disabled {
      color: $red-error;
    }
  }

  .admin-reward-card-buttons {
    @include prefixed(display, "flex");

    button {
      cursor: pointer;
      font-size: 1.6rem;
      display: block;
      border: none;
      padding: 0 2rem;

      &.edit {
        background-color: $yellow;
        color: $white;
      }

      &.remove {
        background-color: $red-error;
        color: $white;
      }
    }
  }

  .delete-reward-modal-container {
    font-size: 2rem;
    text-align: center;

    .buttons {
      button {
        background-color: $medium-green;
        color: $white;
        font-weight: bold;
        padding: 1rem 3rem;
        border: none;
        margin: 1rem 3rem;
        font-size: 2.2rem;
        cursor: pointer;
        @include prefixed(border-radius, $border-radius);

        &:hover,
        &:focus {
          background-color: darken($medium-green, 10%);
        }

        &.no-btn {
          background-color: $red-error;

          &:hover,
          &:focus {
            background-color: darken($red-error, 10%);
          }
        }
      }
    }
  }
}
