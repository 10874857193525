@import "../../styles/imports/";

.order-checkout-container {
  @include prefixed(display, flex);
  position: relative;

  height: 100%;

  .order-checkout {
    padding: 2.5rem 5rem;
    width: 100%;
    overflow: auto;
  }

  .order-checkout-functions {
    margin: 2rem auto;
    max-width: 100rem;
  }
}
