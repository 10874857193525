@import "../../../../../../../../styles/imports/";

.menu-choice {
  margin: 3rem 0;
  @include prefixed(display, flex);
  @include prefixed(justify-content, "space-between");

  button {
    border: none;
    display: block;
    background-color: $medium-green;
    color: $white;
    cursor: pointer;
    padding: 1.5rem;
    width: 15%;
    @include prefixed(border-radius, $border-radius);
    font-size: 2rem;

    &:hover,
    &:focus {
      background-color: $dark-green;
    }
  }
}
