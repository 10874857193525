@import "../../../../../../styles/imports/";

.ticket-menu-item {
  font-size: 1.5rem;
  margin: 2.5rem 0;

  .ticket-menu-type-and-price {
    @include prefixed(display, flex);
    @include prefixed(justify-content, space-between);
    font-size: 1.4rem;
    margin-bottom: 0.75rem;

    .ticket-menu-price {
      font-size: 1.8rem;
    }
  }
}
