@import "../../styles/imports/";

.form-textarea {
  width: 100%;

  label {
    font-family: Roboto;
    font-size: 1.6rem;
    transition: all 0.1s ease-in;
    position: relative;

    &:focus-within {
      color: $dark-green;
    }
  }

  textarea {
    width: 100%;
    min-height: 7rem;
    margin-top: 1rem;
    padding: 1rem;
    @include prefixed(border-radius, $border-radius);
    border: solid 2px $dark-grey;

    &:focus {
      border: solid 3px $dark-green;
      outline: none;
    }
  }

  &.textarea-error {
    label,
    label:focus-within {
      color: $red-error;
    }

    textarea {
      border: solid 3px $red-error;
    }
  }
}
