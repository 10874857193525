@import "../../../../../../../../styles/imports/";

.product-price-change {
  font-size: 1.6rem;

  h4 {
    text-align: center;
    margin-bottom: 2rem;
  }

  .form-field {
    .quantity-and-price {
      .actual-price {
        margin-left: 0.5rem;
      }

      .sale-price-container {
        margin-left: 1.5rem;

        .sale-price {
          font-weight: bold;
          color: $dark-green;
          font-size: 2rem;
          margin-left: 0.5rem;
        }
      }
    }

    .input-group {
      @include prefixed(display, flex);
      @include prefixed(align-items, center);

      .separator {
        display: block;
        margin: 0 auto;
        width: 2rem;
        font-size: 2rem;

        margin: 0 10px;
      }
    }
  }

  form {
    margin-bottom: 2rem;
  }

  .cancel-sale-btn {
    background-color: $red-error;
    color: $white;
    border: none;
    @include prefixed(border-radius, $border-radius);
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    float: right;

    &:hover {
      background-color: darken($red-error, 10%);
    }
  }
}
