@import "../../styles/imports/";

.formRow {
  margin: 1.5rem 0;
  user-select: none;

  &.fileUpload {
    border: solid 1px $medium-green;
    @include prefixed(border-radius, $border-radius);
    padding: 1.5rem 2rem;

    img {
      max-width: 20rem;
      max-height: 15rem;
      display: block;
      margin: 1rem auto;
    }

    button {
      width: 100%;
      padding: 1rem;
      text-align: center;
      display: block;
      border: none;
      background-color: $red-error;
      color: $white;
      @include prefixed(border-radius, $border-radius);
      cursor: pointer;

      &:hover {
        background-color: darken($red-error, 10%);
      }
    }
  }

  &.form-error {
    label,
    label:focus-within {
      color: $red-error;
    }

    input {
      border: 2px solid $red-error;

      &:focus {
        border-bottom: 5px solid $red-error;
      }
    }
  }

  &.halfWidth {
    display: inline-block;
    width: 45%;

    &:nth-child(even) {
      margin-right: 10%;
    }
  }

  label {
    font-family: Roboto;
    font-size: 1.6rem;
    transition: all 0.1s ease-in;
    position: relative;

    &:focus-within {
      color: $dark-green;
    }

    .suffix {
      font-size: 2rem;
      position: relative;
      padding-top: 2rem;
    }
  }

  .currency-symbol {
    position: absolute;
    bottom: 1.7rem;

    font-size: 1.6rem;
  }

  .display-value {
    display: inline-block;
    font-size: 1.8rem;
    text-align: center;
    margin: 1rem;
    font-family: Roboto;
  }

  input {
    display: block;
    width: 100%;
    padding: 1.2rem 2rem;
    margin: 0.75rem auto;
    font-size: 1.6rem;
    text-align: left;
    border-radius: 0.3rem;
    border: none;
    border-bottom: 5px solid $light-grey;
    outline: none;
    background-color: none;
    font-family: Roboto;
    user-select: none;

    &[type="file"] {
      border: none;
    }

    &[type="number"] {
      position: relative;
      user-select: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: auto !important;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }

    &[type="range"] {
      display: inline-block;
      padding: 0;
      cursor: pointer;
      accent-color: $dark-green;
      width: 80%;
      margin: 1.5rem 0;

      &::-webkit-slider-runnable-track {
        height: 1.25rem;
      }

      &::-moz-range-track {
        height: 1.25rem;
      }
    }

    &[type="checkbox"] {
      display: inline-block;
      width: auto;
      margin-left: 1rem;
      transform: scale(1.25);
      position: relative;
      top: 0.2rem;
      accent-color: $dark-green;
    }

    &:focus {
      border-bottom: 5px solid $medium-green;
    }

    &::placeholder {
      color: $light-grey;
    }
  }
}
