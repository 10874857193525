@import "../../../../../../styles/imports/";

.product-card-item {
  @include prefixed(display, "flex");
  flex: 1 0 20%;
  align-self: flex-start;
  max-width: 24%;

  margin: 1.5rem 2rem 1.5rem 0;
  border-radius: $border-radius;

  .card-item-picture {
    @include prefixed(display, "flex");
    @include prefixed(flex-direction, "column");

    width: 100%;
    height: 100%;

    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: $border-radius;

    overflow: hidden;
    text-decoration: none;
  }

  .card-item-pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;

    .food-habits-icons {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 500;

      @include prefixed(display, flex);
    }

    .halal-icon,
    .veggie-icon {
      width: 5rem;
      display: block;
    }
  }

  .card-item-img:not(.halal) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .card-item-info {
    font-family: $montserrat;
    color: #252e48;

    .card-item-name {
      padding: 1.5rem 1rem;
      font-size: 1.5rem;
    }

    .card-item-quantity-and-price {
      @include prefixed(display, "flex");

      .card-item-fields {
        @include prefixed(flex, 1);
        font-size: 1.6rem;
        cursor: pointer;

        &:hover,
        &:active {
          background-color: $dark-green;
        }

        color: $white;
        text-align: center;
        background-color: $medium-green;
        padding: 1rem 0.5rem;
        border-right: 2px solid $white;

        &:last-child {
          border: none;
        }

        .card-item-fields-price {
          font-weight: bold;
          font-size: 1.8rem;
          margin-top: 0.5rem;

          .sale-price {
            color: $yellow;
            margin-right: 0.5rem;
          }

          .base-price-sale {
            color: $light-grey;
            font-size: 1.2rem;
            text-decoration: line-through;
          }

          &.alone {
            margin: 1rem;
          }
        }
      }
    }
  }
}
