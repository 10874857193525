@import "../../../../styles/imports";

.menu-categories {
  @include prefixed(display, flex);
  @include prefixed(flex-direction, column);
  @include prefixed(flex-wrap, "no-wrap");

  height: 100%;
  width: 28rem;
}
