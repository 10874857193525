@import "../../../../../../styles/imports/";

.number-of-orders {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

.untransformed-container {
  margin: 2rem 0;
  font-size: 1.6rem;

  span {
    color: $dark-green;
    font-weight: bold;
  }
}

h4 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.orders-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem; // Adjust the gap between categories as needed

  .category-group {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;

    p {
      font-size: 1.4rem;
    }
  }

  h6 {
    margin-top: 0;
    font-weight: bold;
    font-size: 1.6rem;
  }

  .product-quantity-ordered {
    margin: 5px 0;
  }

  .quantity {
    font-weight: bold;
  }
}
