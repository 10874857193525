@import "../../../../styles/imports/";

.confirm-button {
  border: none;
  font-size: 2.1rem;
  color: $white;

  &:not(.order-recap-validate) {
    min-height: 7.5rem;
    height: 7.5rem;

    background-color: $dark-green;

    text-transform: uppercase;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }

  &.order-recap-validate {
    @include prefixed(display, flex);
    @include prefixed(align-items, center);
    @include prefixed(justify-content, "center");
    @include prefixed(border-radius, 0 0 0 $border-radius);

    width: 25rem;

    text-align: center;

    height: 6rem !important;
    background-color: $medium-green;

    &:hover,
    &:active {
      cursor: pointer;
      background-color: $dark-green;
      color: $white;
    }

    .double-arrow-icon {
      font-size: 2.4rem;
      margin-left: 0.2rem;
    }
  }

  &:hover,
  &:focus {
    background-color: darken($dark-green, 10%);
  }

  &:disabled {
    background-color: darken($light-grey, 10%);
    cursor: not-allowed;
  }
}
